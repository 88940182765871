import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MenuList } from '../../../shared/services/user.service';

@Component({
  selector: 'crew-web-search-menu-card',
  templateUrl: './search-menu-card.component.html',
  styleUrls: ['./search-menu-card.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class SearchMenuCardComponent {
  @Input()
  menuList: MenuList[] = [];

  @Input()
  title = '';

  @Output() selectedMenu = new EventEmitter<string>();

  onClickMenuList(title: string): void {
    this.selectedMenu.emit(title);
  }
}
