export enum BusinessUnit {
  AAFA = 'AAFA',
  AAPI = 'AAPI',
  MQFA = 'MQFA',
  MQPI = 'MQPI',
}

export enum CommandKeyword {
  N4 = 'N4',
  N3 = 'N3',
  N4D = 'N4D',
}

export enum GridHeightOperationType {
  CalendarGridHeight = 'calendarGridHeight',
  FlightDocument = 'flightDocument',
  MobileCalendarEventPosition = 'mobileCalendarEventPosition',
  MobileCalendarEventHeight = 'mobileCalendarEventHeight',
}

export enum DeadHeadAirlineCode {
  RPT = 'RPT',
  NS = 'NS',
  REASSIGNED = 'Reassigned',
  DEADHEAD = 'Deadhead',
  CANCELLED = 'Cancelled',
  AA = 'AA',
  DELAYED = 'Delayed',
}

export enum FlightPosition {
  CA = 'CA',
  FO = 'FO',
  CAPTAIN = 'CAPTAIN',
  FIRST_OFFICER = 'FIRST OFFICER',
}

/**
 * check whether flight got reassigned.
 * ! flightLeg.legAssignmentCode includes this value then we need to show the flight as reassigned.
 * Eg.legAssignmentCode: "AF"
 */
export const REASSIGNMENT_CODE = ['RO', 'RA', 'RS', 'AF'];

/**
 * Delayed Flights
 */
export const DELAYED_CODE = 'DELAYED';

export enum TripSignInButtonTitle {
  SignInConfirmFFD = 'Sign in + confirm FFD',
  SignInTrip = 'Sign in for trip',
  ViewFltPlan = 'View flight plan',
  SignFltPlan = 'Sign flight plan',
  SignFltPlanConfirmFFD = 'Sign flight plan + confirm FFD',
  ConfirmFFD = 'Confirm FFD',
  SignInTripConfirmFFD = 'Sign in for trip + confirm FFD',
  TripSignIn = 'Trip sign in',
  SignedInForTrip = 'You have signed in for the trip',
  SignedInForTripConfirmedFFD = 'You have signed in for the trip & confirmed FFD',
  SignedFlightPlanConfirmedFFD = 'Signed flight plan & confirmed FFD',
  SignInForTripAt = 'Sign in for trip at',
}

export enum BidStatus {
  CONTRACT = 'C',
}

export type TripSignInAlert = {
  type: string;
  message: string;
};

export enum DateFormat {
  HHmm = 'HHmm',
  DD = 'DD',
  DDMMM = 'DDMMM',
  DateTimeFormat24 = 'YYYY-MM-DD HH:mm',
}

export enum CheckListItemParameterType {
  EmpInputAllowed = '[EMP_INPUT_ALLOWED]',
  SelNum = '[SEL_NUM]',
  CkaSelSeat = '[CKA_SEL_CREWSEAT]',
  CkaSelEmpNbr = '[CKA_SEL_CREWEMPNBR]',
  DepartureGate = '[DEPARTUREGATE]',
  ArrivalTime = '[ARRIVALTIME]',
  DepartureTime = '[DEPARTURETIME]',
  ArrivalGate = '[ARRIVALGATE]',
  GmtDepartureDate = '[GMTDEPDATETIME]',
  CabinClass = '[CABIN_CLASS]',
  DepartureDateDay = '[DEPARTUREDATE-DAY]',
  DepartureTimeActual = '[DEPTIME-ACTUAL]',
  FlightOriginationDate = '[FLTORIGINDATE]',
  PlannedTakeoffWay = '[PLANTKOFFRWAY]',
  PlannedOffWeight = '[PLANTKOFFWEIGHT]',
  FlapSettings = '[FLAPSETTINGS]',
  NumberOfDays = '[NUMBER_OF_DAYS]',
  TailNumber = '[TAILNUMBER]',
  WeatherRegion = '[WEATHER REGION]',
  WeatherType = '[WEATHER TYPE]',
  AircraftType = '[AC_TYPE]',
  EmployeeNumber = '[EMPNUMBER]',
  DepartureDate = '[DEPARTUREDATE]',
  FlightNumber = '[FLIGHTNUMBER]',
  CrewSeat = '[CREW_SEAT]',
  CrewBase = '[CREW_BASE]',
  CrewDivision = '[CREW_DIV]',
  DepartureStation = '[DEPARTURESTATION]',
  ArrivalStation = '[ARRIVALSTATION]',
  CrewEquipment = '[CREW_EQP]',
  SequenceNumber = '[SEQ_NUMBER]',
  SequenceDate = '[SEQ_DATE]',
  StartDate = '[START DATE]',
  StartTime = '[START TIME]',
  EndTime = '[END TIME]',
  Date = '[DATE]',
  EndDate = '[END DATE]',
  Month = '[MONTH]',
  Space = '[SPACE]',
  Gap = ' ',
  ForwardSlash = '/',
  FT = 'FT',
  Asterisk = '*',
  C = 'C',
}
