import { computed, Injectable, signal, WritableSignal } from '@angular/core';

import { Colors, CrewUIToastService } from '../../shared/components/crew-ui';
import { CoreService } from '../../shared/services/azure/core.service';
import {
  CheckListItem,
  CheckListItemParameterArray,
  SearchFlightsRequest,
  SearchFlightsResponse,
} from '../../shared/services/azure/core.service.types';
import {
  CallSearchFlight,
  CardTitle,
  MenuList,
  UserService,
} from '../../shared/services/user.service';
import { CheckListItemParameterType } from '../../shared/types';
import { sortArrayBy } from '../../shared/utils/array.utils';
import { formatDate } from '../../shared/utils/date.utils';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  searchFlightResponse: WritableSignal<SearchFlightsResponse | undefined> =
    signal(undefined);

  coreWebsites = computed(() => this.userService.apiDetails()?.websites);

  commandMenuList = computed(() => this.getCommandMenuList());

  flightsMenuList = computed(() => this.getFlightsMenuList());

  /**
   * set search flight response
   * @param response
   */
  setSearchFlightResponse(response: SearchFlightsResponse | undefined): void {
    this.searchFlightResponse.set(response);
  }

  /**
   * Call search flight with fltDepDate, depStation, arrStation, fltNumber
   */
  callSearchFlight(flightDetails: CallSearchFlight): void {
    const {
      arrStation = '',
      depStation = '',
      fltDepDate = '',
      fltNumber = 0,
    } = flightDetails;
    const payload: SearchFlightsRequest = {
      airline: this.userService.emulatedOrDefaultAirlineCode(),
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      appSessionId: this.userService.appSession(),
      arrStation: arrStation,
      businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
      crew: false,
      depStation: depStation,
      emp: this.userService.emulatedOrDefaultEmployeeNumber(),
      empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      fltDepDate: `${formatDate(fltDepDate, 'YYYY-MM-DD')} 00:00:00`,
      fltNumber: fltNumber,
      paxLoadNeeded: false,
      siteMinderEmpId: this.userService.employeeNumber(),
    };
    this.coreService.searchFlights(payload).subscribe({
      next: (response: SearchFlightsResponse) => {
        if (response && response.success && response.legDetailArray) {
          this.setSearchFlightResponse(response);
        } else {
          this.setSearchFlightResponse(undefined);
          this.toastService.showToast({
            textColor: Colors.Afterburner,
            backgroundColor: Colors.Charcoal,
            message: 'No results were returned for this search.',
          });
        }
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  getCommandValue(
    inputParametersArray: CheckListItemParameterArray[],
    commandParameter: string,
  ): string | undefined {
    return inputParametersArray.find(
      (param) => param.commandParameter === commandParameter,
    )?.commandValue;
  }
  createChecklistItemParameters(
    command: CheckListItem,
    params: CheckListItemParameterArray[],
  ): string {
    const TYPE = CheckListItemParameterType;
    let parameters = '';
    command.parametersArray.forEach((p) => {
      switch (p.commandParameter) {
        case TYPE.CkaSelSeat:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.CkaSelEmpNbr:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.CabinClass:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.DepartureDateDay:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.DepartureTimeActual:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.FlightOriginationDate:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.PlannedTakeoffWay:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.PlannedOffWeight:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.FlapSettings:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.TailNumber:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.NumberOfDays:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.WeatherRegion:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.WeatherType:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.AircraftType:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.CrewDivision:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.FlightNumber:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.DepartureDate:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.CrewSeat:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.CrewBase:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.DepartureStation:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.ArrivalStation:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.CrewEquipment:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.SequenceNumber:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.SequenceDate:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.StartDate:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.Date:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.EndDate:
          parameters += this.getCommandValue(params, p.commandParameter);
          break;
        case TYPE.EmployeeNumber:
          parameters += this.userService.emulatedOrDefaultEmployeeNumber();
          break;
        case TYPE.Space:
          parameters += TYPE.Gap;
          break;
        case TYPE.ForwardSlash:
          parameters += TYPE.ForwardSlash;
          break;
        case TYPE.FT:
          parameters += TYPE.FT;
          break;
        case TYPE.Asterisk:
          parameters += TYPE.Asterisk;
          break;
        case TYPE.C:
          parameters += TYPE.C;
          break;
        default:
          parameters += p.commandParameter;
          break;
      }
    });
    return parameters;
  }

  constructor(
    private userService: UserService,
    private coreService: CoreService,
    private toastService: CrewUIToastService,
  ) {}

  private getCommandMenuList(): MenuList[] {
    const list: MenuList[] = [
      {
        title: CardTitle.IR,
        order: 1,
      },
      {
        title: CardTitle.HIC,
        order: 4,
      },
      {
        title: CardTitle.WEBSITES,
        order: 5,
      },
    ];
    if (this.userService.isEmployeeCheckAirman()) {
      list.push(
        {
          title: CardTitle.CKPILOT,
          order: 2,
        },
        {
          title: CardTitle.CKPILOTIR,
          order: 3,
        },
      );
    }
    return sortArrayBy(list, 'order');
  }

  private getFlightsMenuList(): MenuList[] {
    return [
      {
        title: CardTitle.FN,
        order: 1,
      },
    ];
  }
}
