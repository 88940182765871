<ng-container *ngIf="event">
  <section
    [ngClass]="
      event.isStandby &&
      !event.signedIn &&
      (event.standByGate || event.signInAirport)
        ? 'rounded-2xl'
        : 'rounded-t-2xl'
    "
    class="h-full w-full rounded-t-2xl bg-white text-carbon shadow-sm dark:bg-charcoal dark:text-white">
    <div class="p-4">
      <div class="flex items-center justify-between text-[17px] leading-[24px]">
        <ng-container
          *ngIf="event.isStandby && event.signInAirport; else sequence_content">
          <span class="font-medium">
            Standby:
            {{ event.standByGate ? event.standByGate : event.signInAirport }}
          </span>
          <span class="font-light">
            {{ showStandByTime(event) }}
          </span>
        </ng-container>
        <ng-template #sequence_content>
          <span class="font-medium">
            SEQ {{ event.sequence?.sequenceNumber }}
          </span>
          <button
            class="text-[16px] font-normal leading-[24px] text-stratosphere dark:text-troposphere"
            (click)="viewHSS(sabreKeywordDisplay.HSS)">
            View HSS
          </button>
        </ng-template>
      </div>
    </div>
  </section>
  <section
    *ngIf="signedInAndConfirmedFfd(event, true) || signInForTrip(event, true)"
    class="mb-1 mt-px h-full w-full bg-white text-carbon shadow-sm dark:bg-charcoal dark:text-white">
    <div class="p-4">
      <div class="flex items-center justify-between">
        <div class="flex items-center text-[17px] font-normal leading-[24px]">
          <ng-container *ngIf="signedInAndConfirmedFfd(event, true)">
            <div class="mr-3">
              <crew-ui-icon
                [color]="isDark ? colors.Calathea : colors.Zanzibar"
                name="check_circle_outline"></crew-ui-icon>
            </div>
            <div
              class="text-[17px] font-normal leading-[24px] text-carbon dark:text-white">
              {{ signedInAndConfirmedFfd(event, 'title') }}
            </div>
          </ng-container>
          <ng-container *ngIf="signInForTrip(event, true)">
            <div class="mr-3">
              <crew-ui-icon
                [color]="isDark ? colors.Troposphere : colors.Stratosphere"
                name="info" />
            </div>
            <div
              class="text-[17px] font-normal leading-[24px] text-carbon dark:text-white">
              {{ signInForTrip(event, 'title') }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
  <ng-container *ngIf="getFlightDutyPeriods(event).length > 0">
    <section
      *ngFor="
        let dutyPeriod of getFlightDutyPeriods(event);
        let flightDutyIndex = index;
        let dutyPeriodLastIndex = last
      ">
      <ng-container *ngIf="dutyPeriod.flightLegs.length > 0">
        <section
          *ngFor="
            let leg of dutyPeriod.flightLegs;
            let first = first;
            let last = last;
            let legIndex = index
          ">
          <div
            [ngClass]="{ 'mb-0': last, 'rounded-b-2xl': dutyPeriodLastIndex && last && !leg.isExpandOpen, }"
            class="mt-1 h-full w-full bg-white text-carbon shadow-sm dark:bg-charcoal dark:text-white">
            <div [ngClass]="{ 'pb-0': leg.isExpandOpen }" class="p-4">
              <section
                class="mx-3 flex items-center justify-between text-[17px] leading-[24px]">
                <span class="font-normal">
                  {{
                    leg.airlineCode +
                      ' ' +
                      leg.flightNumber +
                      ' . ' +
                      leg.departureStation +
                      ' - ' +
                      leg.arrivalStation
                  }}
                </span>
                <button
                  [ngClass]="{'rotate-180': leg.isExpandOpen}"
                  class="font-normal"
                  (click)="
                    leg.isExpandOpen = !leg.isExpandOpen;
                    $event.stopPropagation()
                  ">
                  <crew-ui-icon
                    [color]="isDark ? colors.White : colors.Carbon"
                    [ngClass]="leg.isExpandOpen ? 'rotate-180': ''"
                    name="arrow_drop_down_circle"></crew-ui-icon>
                </button>
              </section>
              <ng-container *ngIf="leg.isExpandOpen">
                <div
                  class="mb-3 mt-4 h-px w-full bg-turbine px-4 dark:bg-steel"></div>
                <div class="flex cursor-pointer items-center justify-center">
                  <span
                    [ngClass]="[showAirlineCodeStyle(leg, 'style')]"
                    class="rounded-full bg-cirrus px-4 py-1 text-[17px] font-medium leading-[24px] dark:bg-steel">
                    {{ showFlightNumber(leg) }}
                    {{ showAirlineCodeStyle(leg, 'title') }}
                  </span>
                </div>
                <div class="pb-2">
                  <div class="flex items-center justify-between">
                    <div class="flex flex-col pb-1">
                      <span
                        class="text-[30px] font-bold leading-[38px] xs:text-[26px]">
                        {{ leg?.departureStation }}
                      </span>
                      <span
                        *ngIf="validateActualRescheduledDepartureTime(leg)"
                        [ngClass]="[getDepartureColor(leg)]"
                        class="text-[20px] font-medium leading-[24px] xs:text-[18px]"
                        [innerText]="showDepartureTime(leg)"></span>
                      <span
                        [ngClass]="{
                          'line-through':
                            validateActualRescheduledDepartureTime(leg)
                        }"
                        class="text-[20px] font-medium leading-[24px] text-carbon dark:text-white xs:text-[18px]"
                        [innerText]="
                          formatTime(
                            leg?.scheduled?.departureDateTime?.localTime
                          )
                        "></span>
                    </div>
                    <div class="w-full px-5">
                      <crew-ui-divider
                        [text]="leg.blockTime | flightTime : 'flight time'"
                        kind="secondary" />
                    </div>
                    <div class="flex flex-col items-end pb-1">
                      <span
                        class="text-[30px] font-bold leading-[38px] xs:text-[26px]">
                        {{ leg?.arrivalStation }}
                      </span>
                      <span
                        *ngIf="validateActualRescheduledArrivalTime(leg)"
                        [ngClass]="[getArrivalColor(leg)]"
                        class="text-[20px] font-medium leading-[24px] xs:text-[18px]"
                        [innerText]="showArrivalTime(leg)"></span>
                      <span
                        [ngClass]="{
                          'line-through':
                            validateActualRescheduledArrivalTime(leg)
                        }"
                        class="text-[20px] font-medium leading-[24px] text-carbon dark:text-white xs:text-[18px]"
                        [innerText]="
                          formatTime(leg?.scheduled?.arrivalDateTime?.localTime)
                        "></span>
                    </div>
                  </div>
                  <div
                    class="flex items-center justify-between text-[20px] font-normal leading-[25px] text-stratosphere dark:text-troposphere">
                    <button
                      type="button"
                      (click)="doSabreCall(sabreKeywordDisplay.CDI, leg)">
                      {{ leg.departureGate }}
                    </button>
                    <button
                      type="button"
                      (click)="doSabreCall(sabreKeywordDisplay.RGT, leg)">
                      {{ leg.arrivalGate }}
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <section
            *ngIf="leg.isExpandOpen && isFlightPlanSigned(leg)"
            class="my-px h-full w-full bg-white text-carbon shadow-sm dark:bg-charcoal dark:text-white">
            <div class="px-4">
              <div
                class="flex items-center justify-between py-3 text-[17px] leading-[24px]">
                <div
                  class="flex items-center text-[17px] font-normal leading-[24px]">
                  <div class="mr-3">
                    <crew-ui-icon
                      [color]="isDark ? colors.Calathea : colors.Zanzibar"
                      name="check_circle_outline"></crew-ui-icon>
                  </div>
                  <div
                    class="text-[17px] font-normal leading-[24px] text-carbon dark:text-white">
                    Signed flight plan
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            *ngIf="leg.isExpandOpen && isConfirmedFFD(leg)"
            class="my-px h-full w-full bg-white text-carbon shadow-sm dark:bg-charcoal dark:text-white">
            <div class="px-4">
              <div
                class="flex items-center justify-between py-3 text-[17px] leading-[24px]">
                <div
                  class="flex items-center text-[17px] font-normal leading-[24px]">
                  <div class="mr-3">
                    <crew-ui-icon
                      [color]="isDark ? colors.Calathea : colors.Zanzibar"
                      name="check_circle_outline"></crew-ui-icon>
                  </div>
                  <div
                    class="text-[17px] font-normal leading-[24px] text-carbon dark:text-white">
                    Confirmed FFD
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            [ngClass]="{
              'rounded-b-2xl': last && leg.isExpandOpen,
              'pt-4': !leg.isExpandMore
            }"
            *ngIf="leg.isExpandOpen"
            class="mb-1 h-full w-full bg-white text-carbon shadow-sm dark:bg-charcoal dark:text-white">
            <div class="px-4 pb-4">
              <button
                *ngIf="!leg.isExpandMore"
                (click)="
                  leg.isExpandMore = !leg.isExpandMore; $event.stopPropagation()
                "
                class="w-full rounded-xl border bg-transparent py-3 text-[17px] font-medium leading-[24px] text-stratosphere dark:border-steel dark:text-troposphere">
                More details
              </button>
              <div
                [ngClass]="{
                  hidden: !leg.isExpandMore
                }"
                class="text-[17px] font-normal leading-[24px]">
                <div
                  class="flex items-center justify-between border-b border-turbine py-2 dark:border-steel">
                  <div>Equipment type </div>
                  <div>
                    {{ leg?.equipmentQuals?.equipmentType || blankValue }}
                  </div>
                </div>
                <div
                  class="flex items-center justify-between border-b border-turbine py-2 dark:border-steel lt-sm:p-1">
                  <div>Aircraft </div>
                  <div>
                    <button
                      *ngIf="leg?.assignedTail && isAAPIMQPI"
                      type="button"
                      (click)="goToUrl(leg?.assignedTail)"
                      class="text-stratosphere dark:text-troposphere">
                      {{ leg?.assignedTail }}
                    </button>
                    <span *ngIf="leg?.assignedTail && !isAAPIMQPI">{{
                      leg?.assignedTail
                    }}</span>
                    <span *ngIf="!leg?.assignedTail">{{ '--' }}</span>
                  </div>
                </div>
                <div
                  class="flex items-center justify-between border-b border-turbine py-2 dark:border-steel lt-sm:p-1">
                  <div>Time difference</div>
                  <div>{{ leg?.timeZoneDifferenceHHMM || blankValue }}</div>
                </div>
                <div
                  class="flex items-center justify-between border-b border-turbine py-2 dark:border-steel lt-sm:p-1">
                  <div>Seat</div>
                  <div>{{ event.sequencePosition || blankValue }}</div>
                </div>
                <div
                  *ngIf="isAAFAMQFA"
                  class="flex items-center justify-between border-b border-turbine py-2 dark:border-steel lt-sm:p-1">
                  <div>Seat Map</div>
                  <crew-web-seat-map [leg]="leg" />
                </div>
                <div
                  class="flex items-center justify-between border-b border-turbine py-2 dark:border-steel lt-sm:p-1"
                  *ngIf="isAAPIMQPI">
                  <div>Meal </div>
                  <div>{{ leg?.crewMeal || blankValue }}</div>
                </div>
                <!-- <div
                  class="lt-sm:p-1 border-turbine dark:border-steel flex items-center justify-between border-b py-2">
                  <div>Passenger load </div>
                  <div>{{ blankValue }}</div>
                  <crew-web-pax-load [leg]="leg" />
                </div> -->
                <div class="flex items-center justify-between py-2 lt-sm:p-1">
                  <div>Ground time {{ '(' + leg.arrivalStation + ')' }}</div>
                  <div>{{
                    leg?.groundTime ? (leg.groundTime | flightTime) : blankValue
                  }}</div>
                </div>
              </div>
              <div class="mt-2">
                <button
                  *ngIf="leg.isExpandMore"
                  (click)="
                    leg.isExpandMore = !leg.isExpandMore;
                    $event.stopPropagation()
                  "
                  class="w-full rounded-xl border bg-transparent py-3 text-[17px] font-medium leading-[24px] text-stratosphere dark:border-steel dark:text-troposphere">
                  Less details
                </button>
              </div>
            </div>
          </div>
        </section>
      </ng-container>
    </section>
    <ng-container
      *ngIf="
        event.sequence?.numberOfLegs !== event.sequence?.lastFlightLeg?.legIndex
      ">
      <section *ngFor="let dutyPeriod of getFlightDutyPeriods(event)">
        <ng-container
          *ngIf="dutyPeriod.layOverAirport && dutyPeriod.layoverInMinutes">
          <section
            class="mb-1 mt-6 h-full w-full rounded-2xl bg-white text-carbon shadow-sm dark:bg-charcoal dark:text-white">
            <div class="p-4">
              <div class="mx-3 flex items-center justify-between">
                <span class="text-[20px] font-medium leading-[24px]">
                  Layover
                </span>
                <div class="flex">
                  <div
                    class="my-auto mr-2 text-[16px] font-normal leading-[24px]">
                    {{
                      dutyPeriod.layOverAirport +
                        '&nbsp;(' +
                        (dutyPeriod.layoverInMinutes | flightTime) +
                        ')'
                    }}
                  </div>
                  <button
                    [ngClass]="{'rotate-180': dutyPeriod.isExpandMore}"
                    (click)="
                      dutyPeriod.isExpandMore = !dutyPeriod.isExpandMore;
                      $event.stopPropagation()
                    "
                    class="font-normal">
                    <crew-ui-icon
                      [color]="isDark ? colors.White : colors.Carbon"
                      name="arrow_drop_down_circle"></crew-ui-icon>
                  </button>
                </div>
              </div>
              <div class="mx-3" *ngIf="dutyPeriod.isExpandMore">
                <div
                  class="mb-3 mt-4 h-px w-full bg-turbine px-4 dark:bg-steel"></div>
                <div
                  class="flex items-center justify-between text-[17px] leading-[24px]"
                  *ngIf="getCrewStaying().length > 0">
                  <span class="font-normal"> Crew staying here </span>
                  <div class="flex">
                    <div class="mr-2 text-[16px] font-normal leading-[24px]">
                      {{ getCrewStaying().length }}
                    </div>
                    <button
                      class="font-normal"
                      [ngClass]="{'rotate-180': isExpandedCrewStay}"
                      (click)="
                        isExpandedCrewStay = !isExpandedCrewStay;
                        $event.stopPropagation()
                      ">
                      <crew-ui-icon
                        [color]="isDark ? colors.White : colors.Carbon"
                        name="arrow_drop_down_circle"></crew-ui-icon>
                    </button>
                  </div>
                </div>
                <div *ngIf="isExpandedCrewStay && getCrewStaying().length > 0">
                  <div
                    class="mb-3 mt-4 h-px w-full bg-turbine px-4 dark:bg-steel"></div>
                  <div
                    class="justify-left m-2 mt-4 flex items-center p-2"
                    *ngFor="let crewStay of getCrewStaying()">
                    <crew-ui-icon
                      [color]="colors.Nickel"
                      name="account_circle" />
                    <div class="top-10 ml-3 mr-3">
                      <h2
                        *ngIf="crewStay.fltPosition"
                        class="text-[15px] text-xs leading-[24px] text-aluminum">
                        {{ getFlightPosition(crewStay.fltPosition) }}
                      </h2>
                      <p
                        *ngIf="crewStay.empNum"
                        class="text-[15px] font-semibold leading-[24px]">
                        {{ crewStay.empNum }}
                      </p>
                      <p class="text-[15px] font-semibold leading-[24px]">
                        {{ crewStay.firstName }}, {{ crewStay.lastName }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="mb-3 mt-4 h-px w-full bg-turbine px-4 dark:bg-steel"
                  *ngIf="getCrewStaying().length > 0"></div>
                <div
                  class="my-px h-full w-full bg-white text-carbon dark:bg-charcoal dark:text-white">
                  <div class="flex w-full items-center">
                    <div>
                      <crew-ui-icon
                        name="description"
                        [color]="
                          isDark ? colors.White : colors.Carbon
                        "></crew-ui-icon>
                    </div>
                    <div class="ml-4 flex w-full flex-col text-left">
                      <div
                        class="text-[16px] font-medium leading-[24px] text-carbon dark:text-white">
                        CREW INFO
                      </div>
                      <div
                        class="text-left text-[16px] font-normal leading-[24px] text-carbon dark:text-white"
                        >Hotel / limo assignment</div
                      >
                    </div>
                    <button
                      *ngIf="event.sequence"
                      (click)="
                        doSabreCall(
                          sabreKeywordDisplay.CREWHOTELLIMOINFO,
                          event.sequence.lastFlightLeg
                        )
                      "
                      class="text-[16px] font-normal leading-[24px] text-stratosphere dark:text-troposphere">
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ng-container>
      </section>
    </ng-container>
  </ng-container>
</ng-container>

<!-- <ng-template #noFlightLegs>
  <section
    class="dark:bg-charcoal text-carbon mb-0 mt-1 h-full w-full rounded-b-2xl bg-white shadow-sm dark:text-white">
    <div class="p-4">
      <section
        class="mx-3 flex items-center justify-between text-[17px] leading-[24px]">
          <span class="text-[20px] font-medium leading-[24px]">
              Layover
            </span>
            <div class="flex">
              <div class="mr-2 text-[16px] font-normal leading-[24px]">CLT</div>
            </div>
      </section>
    </div>
  </section>
</ng-template> -->
