import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import {
  Colors,
  CrewUIDarkModeService,
  CrewUIDividerComponent,
  CrewUIIconComponent,
  CrewUIInputComponent,
} from '../../shared/components/crew-ui';
import { SabreDocumentComponent } from '../../shared/components/sabre-document/sabre-document.component';
import { SabreDocumentService } from '../../shared/components/sabre-document/sabre-document.service';
import { SettingsButtonComponent } from '../../shared/components/settings-button/settings-button.component';
import {
  CheckListItem,
  CheckListItemParameterArray,
  // CheckListItemParameterType,
  CoreServiceWebsite,
  SearchFlightsResponse,
} from '../../shared/services/azure/core.service.types';
import { InsightsService } from '../../shared/services/azure/insights.service';
import { BreakpointService } from '../../shared/services/breakpoint.service';
import { CommandService } from '../../shared/services/command.service';
import {
  CallSearchFlight,
  CardTitle,
  CheckAirmanMenu,
  MenuList,
  UserService,
} from '../../shared/services/user.service';
import {
  BusinessUnit,
  CheckListItemParameterType,
  CommandKeyword,
} from '../../shared/types';
import { calendarOptions } from '../calendar/calendar.constant';
import { OverviewService } from '../overview/overview.service';
import { SearchService } from './search.service';
import { SearchCommandListCardComponent } from './search-command-list-card/search-command-list-card.component';
import { SearchFilterCardComponent } from './search-filter-card/search-filter-card.component';
import { SearchMenuCardComponent } from './search-menu-card/search-menu-card.component';

export enum CloseButtonType {
  FilterCard = 'filterCard',
  SabreClose = 'sabreClose',
}

@Component({
  selector: 'crew-web-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    SettingsButtonComponent,
    SearchMenuCardComponent,
    CrewUIIconComponent,
    CrewUIDividerComponent,
    SearchFilterCardComponent,
    SearchCommandListCardComponent,
    CrewUIInputComponent,
    FormsModule,
    SabreDocumentComponent,
  ],
})
export class SearchComponent implements OnInit {
  constructor(
    private breakpointService: BreakpointService,
    private commandService: CommandService,
    private darkModeService: CrewUIDarkModeService,
    private insightsService: InsightsService,
    private sabreDocumentService: SabreDocumentService,
    private searchService: SearchService,
    private userService: UserService,
    private overviewService: OverviewService,
  ) {
    this.commandService.getCheckListItem();
    this.displayDate = this.getFormattedDate(this.flightDate);
  }

  readonly closeButtonType = CloseButtonType;

  commandTitle = ``;

  commandTitleList = [
    CardTitle.IR.toString(),
    CardTitle.HIC.toString(),
    CardTitle.WEBSITES.toString(),
    CardTitle.CKPILOT.toString(),
    CardTitle.CKPILOTIR.toString(),
  ];

  readonly cardTitle = CardTitle;

  colors = Colors;

  currentDate: Date = new Date();

  visibleCommands?: CheckListItem[] = [];
  selectedInputRequiredCommand?: CheckListItem;
  selectedHICommandDetailsModal?: CheckListItem;
  selectedHICommand?: CheckListItem;

  websites?: CoreServiceWebsite[] = [];
  selectedWebsitesRequiredModal?: CoreServiceWebsite[];
  inputParametersArray: CheckListItemParameterArray[] = [];

  showSearchFlightModal = false;
  showInputRequiredModal = false;
  showInputRequiredDetailsModal = false;
  showWebsitesDetailsModal = false;
  showHICommandDetailsModal = false;
  showFullInfo = false;

  selectedHICommandsData = '';

  flightNumber?: number;
  flightDate = new Date();
  displayDate = '';
  depAirport = '';
  arrAirport = '';

  searchInput = '';
  // lastUpdatedTime?: string;

  crewInfoVisible = calendarOptions.crewInfoVisible;

  get hiCommands(): CheckListItem[] {
    return this.commandService.hiCommands();
  }

  get inputCommands(): CheckListItem[] {
    return this.commandService.inputCommands();
  }

  get checkPilotCommands(): CheckListItem[] {
    return this.commandService.checkPilotCommands();
  }

  get checkPilotInputCommands(): CheckListItem[] {
    return this.commandService.checkPilotInputCommands();
  }

  get coreWebsites(): CoreServiceWebsite[] | undefined {
    return this.searchService.coreWebsites();
  }

  get isDark(): boolean {
    return this.darkModeService.isDarkMode();
  }

  get flightResponse(): SearchFlightsResponse | undefined {
    return this.searchService.searchFlightResponse();
  }

  set flightResponse(response) {
    this.searchService.setSearchFlightResponse(response);
  }

  get hasSabreResponse(): boolean {
    return this.sabreDocumentService.hasSabreResponse();
  }

  get isEmployeeCheckAirman(): boolean | undefined {
    return this.userService.isEmployeeCheckAirman();
  }

  get commandMenuList(): MenuList[] {
    return this.searchService.commandMenuList();
  }

  get flightsMenuList(): MenuList[] {
    return this.searchService.flightsMenuList();
  }

  ngOnInit(): void {
    this.insightsService.trackPageView({
      name: 'Search',
    });
  }

  onToggleCheckAirmanList(checkAirman: CheckAirmanMenu): void {
    checkAirman.isOpen = !checkAirman.isOpen;
  }

  /**
   * On select commandMenuList title
   * @param title
   */
  onSelectInputRequiredModal(title: string): void {
    this.searchInput = '';
    this.commandTitle = title;
    if (title === CardTitle.IR) {
      this.showInputRequiredModal = true;
      this.showHICommandDetailsModal = false;
      this.visibleCommands = this.inputCommands;
    } else if (title === CardTitle.HIC) {
      this.showInputRequiredModal = true;
      this.showInputRequiredDetailsModal = false;
      this.visibleCommands = this.hiCommands;
    } else if (title === CardTitle.WEBSITES) {
      this.showInputRequiredModal = true;
      this.showHICommandDetailsModal = false;
      this.showInputRequiredDetailsModal = false;
      this.websites = this.coreWebsites;
    } else if (title === CardTitle.CKPILOT) {
      this.showInputRequiredModal = true;
      this.showHICommandDetailsModal = false;
      this.visibleCommands = this.checkPilotCommands;
    } else if (title === CardTitle.CKPILOTIR) {
      this.showInputRequiredModal = true;
      this.showHICommandDetailsModal = false;
      this.visibleCommands = this.checkPilotInputCommands;
    }
  }

  /**
   * On select flights menu title
   * @param title
   */
  onToggleFlightModal(): void {
    this.flightNumber = undefined;
    this.depAirport = '';
    this.arrAirport = '';
    this.flightResponse = undefined;
    this.showFullInfo = false;
    this.showSearchFlightModal = !this.showSearchFlightModal;
  }

  onClickCloseBtn(type: string): void {
    this.searchInput = '';
    this.showInputRequiredModal = false;
    this.showHICommandDetailsModal = false;
    if (this.commandTitle && type === CloseButtonType.SabreClose) {
      this.onSelectInputRequiredModal(this.commandTitle);
    }
  }

  toggleSearchView(): object {
    return {
      hidden: this.showInputRequiredModal && this.breakpointService.isLtSm,

      'w-1/3': this.breakpointService.isGtXs,
    };
  }

  getFormattedDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' })
      .format(date)
      .toUpperCase();
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  filterCommandMenuList(input: string): void {
    if (this.commandTitleList.includes(this.commandTitle)) {
      if (this.commandTitle !== CardTitle.WEBSITES) {
        let commandList =
          this.commandTitle === CardTitle.HIC
            ? this.hiCommands
            : this.inputCommands;
        if (this.isEmployeeCheckAirman) {
          commandList =
            this.commandTitle === CardTitle.CKPILOT
              ? this.checkPilotCommands
              : this.checkPilotInputCommands;
        }
        this.visibleCommands = commandList?.filter(
          (c) =>
            c.commandKeywordDisplay?.toLowerCase()?.includes(input) ||
            c.commandDescription?.toLowerCase()?.includes(input),
        );
      } else {
        this.websites = this.coreWebsites?.filter((c) =>
          c.name.toLowerCase().includes(input),
        );
      }
    }
  }

  searchCommands(input: string): void {
    this.searchInput = input.trim().toLowerCase();
    if (this.commandTitle) {
      this.filterCommandMenuList(this.searchInput);
    }
  }

  showSearchFlightInput(): void {
    this.flightResponse = undefined;
    this.showFullInfo = false;
  }

  padTo2Digits(num: number): string {
    return num.toString().padStart(2, '0');
  }

  searchFlight(): void {
    const flightDetails: CallSearchFlight = {
      arrStation: this.arrAirport.toUpperCase(),
      depStation: this.depAirport.toUpperCase(),
      fltDepDate: this.displayDate,
      fltNumber: Number(this.flightNumber),
    };
    this.searchService.callSearchFlight(flightDetails);
  }
  showCompleteFlightInfo(): void {
    this.showFullInfo = true;
    this.showSearchFlightModal = false;
  }
  closeInputRequiredCommandModal(): void {
    this.showInputRequiredDetailsModal = false;
    this.inputParametersArray = [];
  }

  formatFlightResponseDate(date: string): string {
    const inputDate = new Date(date);
    const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const dayOfWeek = daysOfWeek[inputDate.getDay()];
    const dayOfMonth = inputDate.getDate().toString().padStart(2, '0');
    const monthAbbreviation = new Intl.DateTimeFormat('en-US', {
      month: 'short',
    })
      .format(inputDate)
      .toUpperCase();
    const yearAbbreviation = inputDate.getFullYear().toString().slice(-2);
    return dayOfWeek + ' ' + dayOfMonth + monthAbbreviation + yearAbbreviation;
  }

  openInputRequiredCommandModal(command: CheckListItem): void {
    this.selectedInputRequiredCommand = command;
    this.showInputRequiredDetailsModal = true;
    //this.setInputParameterArray(command);
    // this.selectedInputRequiredCommand.parametersArray.forEach((param) => {
    //   if (
    //     /\[.*\]/.test(param.commandParameter) &&
    //     param.commandParameter !== TYPE.EmployeeNumber
    //   ) {
    //     this.inputParametersArray.push({
    //       commandParameter: param.commandParameter,
    //       commandParameterDesc: param.commandParameterDesc,
    //       commandValue: this.overviewService.replaceParameterWithFlightData(
    //         param.commandParameter,
    //       ),
    //     });
    //   }
    // });
  }

  setInputParameterArray(command?: CheckListItem): void {
    const TYPE = CheckListItemParameterType;
    if (command && command?.parametersArray?.length > 0) {
      command.parametersArray.forEach((param) => {
        if (
          /\[.*\]/.test(param.commandParameter) &&
          param.commandParameter !== TYPE.EmployeeNumber
        ) {
          this.inputParametersArray.push({
            commandParameter: param.commandParameter,
            commandParameterDesc: param.commandParameterDesc,
            commandValue: this.overviewService.replaceParameterWithFlightData(
              param.commandParameter,
            ),
          });
        }
      });
    }
  }

  openHICommandDetailsModal(command?: CheckListItem): void {
    this.selectedHICommandDetailsModal = command;
    this.showHICommandDetailsModal = true;
    this.showInputRequiredDetailsModal = false;
    this.selectedHICommandsData = '';
    this.selectedHICommand = command;
    this.loadHICommandDetails(command);
  }

  openCommandDetailsModal(command: CheckListItem): void {
    this.inputParametersArray = [];
    this.setInputParameterArray(command);
    if (this.commandTitle && this.commandTitle === CardTitle.HIC) {
      this.openHICommandDetailsModal(command);
    } else {
      this.openInputRequiredCommandModal(command);
    }
  }

  loadHICommandDetails(command?: CheckListItem): void {
    if (!command) return;
    const {
      commandId,
      commandKeyword,
      commandKeywordDisplay,
      systemName,
      view,
    } = command;
    const parameters = this.searchService.createChecklistItemParameters(
      command,
      this.inputParametersArray,
    );
    const commonRequestArray = [
      {
        commandId: commandId,
        parameters: parameters,
        systemName: systemName,
        keyword: commandKeyword,
        keywordDisplay: commandKeywordDisplay,
        deleteHI6Message: false,
        firstDeleteHI6Message: false,
      },
    ];
    this.sabreDocumentService.doSabreCommandCall(commonRequestArray, view);
  }

  getFancyFlightDate(time: string): string {
    const dateObject = new Date(time);
    const month = dateObject.toLocaleString('en-US', { month: 'short' });
    const day = dateObject.getDate();
    return `${month.toUpperCase()} ${day.toString().padStart(2, '0')}`;
  }

  getFlyingTime(time: string): string {
    const [hours, minutes] = time.split(':');
    return `${hours}h ${minutes}m`;
  }

  isCommandValuePresent(): boolean {
    let response = false;
    if (this.selectedInputRequiredCommand?.buCode === BusinessUnit.AAFA) {
      response =
        this.selectedInputRequiredCommand.commandKeyword === CommandKeyword.N4
          ? this.validateWithKeyword()
          : this.validateCommandValue();
    } else if (
      this.selectedInputRequiredCommand?.buCode === BusinessUnit.AAPI
    ) {
      response =
        this.selectedInputRequiredCommand.commandKeyword ===
          CommandKeyword.N3 ||
        this.selectedInputRequiredCommand.commandKeyword === CommandKeyword.N4
          ? this.validateWithKeyword()
          : this.validateCommandValue();
    } else if (
      this.selectedInputRequiredCommand?.buCode === BusinessUnit.MQPI
    ) {
      response =
        this.selectedInputRequiredCommand.commandKeyword === CommandKeyword.N4D
          ? this.validateWithKeyword()
          : this.validateCommandValue();
    } else {
      response = this.validateCommandValue();
    }
    return response;
  }

  validateWithKeyword(): boolean {
    const paramArray = this.inputParametersArray.filter(
      (param) => param.commandValue !== '',
    );
    return paramArray.length >= this.inputParametersArray.length - 1
      ? true
      : false;
  }

  validateCommandValue(): boolean {
    return this.inputParametersArray.every((param) => !!param.commandValue);
  }

  isFlightDataPresent(): boolean {
    return (
      !!this.depAirport &&
      !!this.flightNumber &&
      !!this.arrAirport &&
      !!this.flightDate
    );
  }

  toggleCrewInfoVisibility(): void {
    this.crewInfoVisible = !this.crewInfoVisible;
  }
}
