import { computed, Injectable, signal, WritableSignal } from '@angular/core';

import { ScheduleService } from '../../shared/services/azure/schedule.service';
import {
  CrewMember,
  Events,
  FlightDutyPeriods,
  FlightLeg,
  GetDashboardInfoRequest,
  GetDashboardInfoResponse,
  Sequence,
  SequenceEvent,
  SequenceFlightLeg,
  TripSingInInfo,
} from '../../shared/services/azure/schedule.service.types';
import { UserService } from '../../shared/services/user.service';
import { formatDate, unsetTimeZone } from '../../shared/utils/date.utils';
import {
  getFlightDataFromParameter,
  getScheduleFlightDataFromParameter,
} from '../../shared/utils/flightLeg.utils';
import { deepClone } from '../../shared/utils/string.utils';
export enum EventTypes {
  Sequence,
  NonSequence,
}
@Injectable({
  providedIn: 'root',
})
export class OverviewService {
  constructor(
    private userService: UserService,
    private scheduleService: ScheduleService,
  ) {}

  showLocationModal: WritableSignal<boolean> = signal(false);

  showNotificationModal: WritableSignal<boolean> = signal(false);

  private dashboardInfo: WritableSignal<GetDashboardInfoResponse | undefined> =
    signal(undefined);

  activeOrNextSequences = computed(
    () =>
      this.dashboardInfo()?.dashBoardResponse?.activeOrNextSequences ||
      ([] as Sequence[]),
  );

  todaysEvents = computed(() => this.setTodaysEvents(this.dashboardInfo()));

  upcomingEvents = computed(() => this.setUpcomingEvents(this.dashboardInfo()));

  lastUpdatedTime: WritableSignal<Date> = signal(new Date());

  getType(event: Events): EventTypes {
    if (event.sequenceNumber !== null) return EventTypes.Sequence;
    else return EventTypes.NonSequence;
  }

  getShortMonth(date: Date): string {
    return date.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
    });
  }

  toggleShowLocationModal(): void {
    this.showLocationModal.set(!this.showLocationModal());
  }

  loadDashBoardInfo(): void {
    this.lastUpdatedTime.set(new Date());
    const payload: GetDashboardInfoRequest = {
      localDate: formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      siteMinderEmployeeId: this.userService.employeeNumber(),
      employeeLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
      appSessionId: this.userService.appSession(),
    };
    this.scheduleService
      .getDashBoardInfo(payload)
      .subscribe((response: GetDashboardInfoResponse) => {
        this.dashboardInfo.set(response);
      });
  }

  toggleNotificationModal(): void {
    this.showNotificationModal.set(!this.showNotificationModal());
  }

  activeFlightPresentLeg(dashboardInfo: Events[]): FlightLeg {
    let presentLeg = {} as FlightLeg;
    if (
      dashboardInfo &&
      dashboardInfo.length > 0 &&
      dashboardInfo[0]?.sequence &&
      dashboardInfo[0]?.sequence?.activeFlightPresentLeg &&
      typeof dashboardInfo[0]?.sequence?.activeFlightPresentLeg === 'object' &&
      Object.keys(dashboardInfo[0]?.sequence?.activeFlightPresentLeg).length !==
        0
    ) {
      presentLeg = dashboardInfo[0]?.sequence?.activeFlightPresentLeg;
    }
    return presentLeg;
  }

  getActivePositionCode(dashboardInfo: Events[]): string {
    return dashboardInfo[0]?.sequence?.positionCode || ``;
  }

  getActiveFlightPresentLeg(dashboardInfo: Events[]): FlightLeg {
    return this.activeFlightPresentLeg(dashboardInfo);
  }

  replaceParameterWithFlightData(commandParameter: string): string {
    const todaysEvents = this.todaysEvents();
    if (
      todaysEvents &&
      todaysEvents.length > 0 &&
      todaysEvents[0].sequence &&
      todaysEvents[0].sequence?.activeFlightPresentLeg &&
      typeof todaysEvents[0]?.sequence?.activeFlightPresentLeg === 'object' &&
      Object.keys(todaysEvents[0]?.sequence?.activeFlightPresentLeg).length !==
        0
    ) {
      const activeFlightPresentLeg =
        todaysEvents[0]?.sequence?.activeFlightPresentLeg;
      const response = getFlightDataFromParameter(
        commandParameter,
        activeFlightPresentLeg,
        this.userService.emulatedOrDefaultEmployeeNumber()?.toString(),
      );
      return getScheduleFlightDataFromParameter(
        response,
        activeFlightPresentLeg,
        todaysEvents[0]?.sequence,
      );
    }
    return '';
  }

  private getActiveOrNextSequences(
    dashboardInfo: GetDashboardInfoResponse,
    eventDate: string,
    activeOrNextSequences: Sequence[],
  ): {
    activeOrNextSequences: Sequence[];
    activeFlightData: SequenceFlightLeg;
  } {
    const activeFlightData: SequenceFlightLeg = {
      activeFlightDutyPeriods: [] as FlightDutyPeriods[],
      activeFlightLeg: {} as FlightLeg,
      activeFlightPosition: '' as string,
      activeFlightPresentLeg: {} as FlightLeg,
      activeSequence: {} as Sequence,
    };
    dashboardInfo?.dashBoardResponse?.activeOrNextSequences.filter(
      (seq: Sequence) => {
        let sequenceEvent = {} as Sequence;
        if (seq.flightDutyPeriods && seq.flightDutyPeriods.length > 0) {
          const sequenceFDP = seq.flightDutyPeriods.filter(
            (fdp: FlightDutyPeriods) => {
              let fdPeriod = {} as FlightDutyPeriods;
              if (fdp.flightLegs && fdp.flightLegs.length > 0) {
                const sequenceFlightLegs = fdp.flightLegs.filter(
                  (flightLeg: FlightLeg) => {
                    flightLeg.isExpandOpen = false;
                    flightLeg.isExpandMore = true;
                    const activeFlightDataPresent =
                      this.getTodayFlightLegBetweenEventDate(
                        flightLeg,
                        eventDate,
                      );
                    if (activeFlightDataPresent) {
                      activeFlightData.activeFlightPresentLeg = flightLeg;
                    }
                    const activeFlightLeg = this.getTodayFlightLegs(
                      flightLeg,
                      eventDate,
                    );
                    if (activeFlightLeg) {
                      if (
                        (typeof flightLeg.fltPlanSignature === 'object' &&
                          flightLeg.fltPlanSignature !== null) ||
                        flightLeg.deadHead
                      ) {
                        activeFlightData.activeFlightLeg = flightLeg;
                        activeFlightData.activeFlightDutyPeriods.push(fdp);
                      }
                      return flightLeg;
                    }
                    return false;
                  },
                );
                if (sequenceFlightLegs.length > 0) {
                  fdPeriod = fdp;
                  fdPeriod.isExpandMore = false;
                  fdPeriod.flightLegs = [] as FlightLeg[];
                  fdPeriod.flightLegs = sequenceFlightLegs;
                  return fdp;
                }
              }
              return false;
            },
          );
          if (sequenceFDP.length > 0) {
            sequenceEvent = seq;
            sequenceEvent.flightDutyPeriods = [] as FlightDutyPeriods[];
            sequenceEvent.flightDutyPeriods = sequenceFDP;
          }
        }
        if (Object.keys(sequenceEvent).length !== 0) {
          activeOrNextSequences.push(sequenceEvent);
          activeFlightData.activeFlightPosition = sequenceEvent.positionCode;
          activeFlightData.activeSequence = seq;
        }
      },
    );
    activeFlightData.activeFlightPosition = this.getActiveLegFlightPosition(
      activeFlightData.activeFlightLeg,
      activeFlightData.activeFlightPosition,
    );
    return {
      activeOrNextSequences: activeOrNextSequences,
      activeFlightData: activeFlightData,
    };
  }

  private getSequenceEvent(
    result: SequenceEvent,
    event: Events,
    dashboardInfo: GetDashboardInfoResponse,
    eventDate: string,
    activeOrNextSequencesInit: Sequence[],
  ): SequenceEvent {
    if (
      event.originationDate &&
      event.sequenceNumber &&
      dashboardInfo?.dashBoardResponse?.activeOrNextSequences?.length > 0
    ) {
      const { activeOrNextSequences, activeFlightData } =
        this.getActiveOrNextSequences(
          deepClone(dashboardInfo),
          eventDate,
          activeOrNextSequencesInit,
        );
      if (activeOrNextSequences?.length > 0) {
        result.activeSequence = activeFlightData.activeSequence;
        result.sequenceNumber = event.sequenceNumber.toString() ?? '';
        if (activeOrNextSequences[0]?.tripSignInInfo) {
          result.tripSignInInfo = activeOrNextSequences[0]?.tripSignInInfo;
        }
        if (activeOrNextSequences[0]?.flightDutyPeriods) {
          result.numberOfLegs = activeOrNextSequences[0].numberOfLegs;
          result.flightDutyPeriods =
            activeOrNextSequences[0]?.flightDutyPeriods;
        }
        result.activeFlightLeg = activeFlightData?.activeFlightLeg;
        result.positionCode = activeFlightData?.activeFlightPosition;
        result.activeFlightPresentLeg =
          activeFlightData?.activeFlightPresentLeg;
        if (result.flightDutyPeriods && result.flightDutyPeriods.length > 0) {
          result.firstFlightLeg = result.flightDutyPeriods[0].flightLegs[0];
          const lastFlightDutyPeriods =
            result.flightDutyPeriods[result.flightDutyPeriods.length - 1];
          result.lastFlightLeg =
            lastFlightDutyPeriods?.flightLegs[
              lastFlightDutyPeriods?.flightLegs.length - 1
            ];
        }
        /* In between the sequence doesn't have leg for the day */
      } else if (activeOrNextSequences?.length === 0 && event.sequenceNumber) {
        result.sequenceNumber = event.sequenceNumber.toString();
        result.positionCode = event.sequencePosition;
      }
    }
    return result;
  }

  private initSequenceEvent(): SequenceEvent {
    return {
      sequenceNumber: '--',
      flightDutyPeriods: [] as FlightDutyPeriods[],
      positionCode: '--',
      firstFlightLeg: {} as FlightLeg,
      lastFlightLeg: {} as FlightLeg,
      activeFlightLeg: {} as FlightLeg,
      tripSignInInfo: {} as TripSingInInfo,
      numberOfLegs: -1,
    } as SequenceEvent;
  }

  private setTodaysEvents(response?: GetDashboardInfoResponse): Events[] {
    if (response && response?.dashBoardResponse?.todaysEvents?.length > 0) {
      response?.dashBoardResponse?.todaysEvents.filter((event: Events) => {
        const result = this.initSequenceEvent();
        event.sequence = this.getSequenceEvent(
          result,
          event,
          response,
          new Date().toString(),
          [] as Sequence[],
        );
      });
      return response?.dashBoardResponse?.todaysEvents;
    }
    return [] as Events[];
  }

  private setUpcomingEvents(response?: GetDashboardInfoResponse): Events[] {
    if (response && response?.dashBoardResponse?.upcomingEvents?.length > 0) {
      response?.dashBoardResponse?.upcomingEvents.filter((event: Events) => {
        const result = this.initSequenceEvent();
        event.sequence = this.getSequenceEvent(
          result,
          event,
          response,
          event.originationDate,
          [] as Sequence[],
        );
      });
      return response?.dashBoardResponse?.upcomingEvents;
    }
    return [] as Events[];
  }

  private isFlightLegDateBetween(
    departureDateTime: string,
    arrivalDateTime: string,
    eventDate: string,
    format = 'YYYY-MM-DD HH:mm',
  ): boolean {
    return (
      formatDate(eventDate, format) >= formatDate(departureDateTime, format) &&
      formatDate(eventDate, format) <= formatDate(arrivalDateTime, format)
    );
  }

  private getTodayFlightLegBetweenEventDate(
    fl: FlightLeg,
    eventDate: string,
  ): boolean {
    let response = false;
    if (
      fl.scheduled &&
      this.isFlightLegDateBetween(
        fl.scheduled?.departureDateTime?.localTime,
        fl.scheduled?.arrivalDateTime?.localTime,
        eventDate,
      )
    ) {
      response = true;
    }
    if (
      fl.actual &&
      this.isFlightLegDateBetween(
        fl.actual?.departureDateTime?.localTime,
        fl.actual?.arrivalDateTime?.localTime,
        eventDate,
      )
    ) {
      response = true;
    }
    if (
      fl.reScheduled &&
      this.isFlightLegDateBetween(
        fl.reScheduled?.departureDateTime?.localTime,
        fl.reScheduled?.arrivalDateTime?.localTime,
        eventDate,
      )
    ) {
      response = true;
    }
    return response;
  }

  private isFlightLegDateEqual(
    departureDateTime: string,
    arrivalDateTime: string,
    eventDate: string,
  ): boolean {
    return (
      formatDate(unsetTimeZone(departureDateTime)) === formatDate(eventDate) ||
      formatDate(unsetTimeZone(arrivalDateTime)) === formatDate(eventDate)
    );
  }

  private getTodayFlightLegs(fl: FlightLeg, eventDate: string): boolean {
    let response = false;
    if (
      fl.scheduled &&
      this.isFlightLegDateEqual(
        fl.scheduled?.departureDateTime?.localTime,
        fl.scheduled?.arrivalDateTime?.localTime,
        eventDate,
      )
    ) {
      response = true;
    }
    if (
      fl.actual &&
      this.isFlightLegDateEqual(
        fl.actual?.departureDateTime?.localTime,
        fl.actual?.arrivalDateTime?.localTime,
        eventDate,
      )
    ) {
      response = true;
    }
    if (
      fl.reScheduled &&
      this.isFlightLegDateEqual(
        fl.reScheduled?.departureDateTime?.localTime,
        fl.reScheduled?.arrivalDateTime?.localTime,
        eventDate,
      )
    ) {
      response = true;
    }
    return response;
  }
  private validateCrewMemberIsCaptain(leg: FlightLeg): string {
    let flightPosition = ``;
    if (
      leg.crewData &&
      leg.crewData.crewMember &&
      leg.crewData.crewMember.length > 0
    ) {
      const creMember = leg.crewData.crewMember.find(
        (member: CrewMember) =>
          member.empNum?.toString() === this.userService.paddedEmployeeNumber(),
      );
      flightPosition =
        creMember && creMember.fltPosition
          ? creMember.fltPosition
          : flightPosition;
    }
    return flightPosition;
  }
  private getActiveLegFlightPosition(leg: FlightLeg, code: string): string {
    let positionCode = code;
    if (leg && leg.crewData !== null) {
      positionCode = this.validateCrewMemberIsCaptain(leg);
    }
    return positionCode ? positionCode : code;
  }
}
