import { Injectable } from '@angular/core';
import { EMPTY, lastValueFrom, Observable, of, tap } from 'rxjs';

import { MessageService } from '../../../routes/message/message.service';
import { BusinessUnit } from '../../types';
import { sortArrayBy } from '../../utils/array.utils';
import { InsightsService } from '../azure/insights.service';
import { MessageApiService } from '../azure/message.service';
import {
  FullMessageData,
  GetCCIMsgDetailByMsgIdRequest,
  GetEmployeeMessagesRequest,
  GetEmployeeMessagesResponse,
  ManageEmployeeMessageRequest,
  ManageEmployeeMessageResponse,
} from '../azure/message.service.types';
import { UserService } from '../user.service';
import { IdbService, Permissions, Stores } from './idb.service';
@Injectable({
  providedIn: 'root',
})
export class MessageIdbService {
  constructor(
    private userService: UserService,
    private messageApiService: MessageApiService,
    private messageService: MessageService,
    private idbService: IdbService,
    private insights: InsightsService,
  ) {}

  /**
   * @description Add a message to IndexedDB.
   */
  async createSavedMessage(messageData: FullMessageData): Promise<boolean> {
    return this.idbService.dbPromise
      .then(async (db) => {
        const transaction = db.transaction(
          Stores.SavedMessages,
          Permissions.Write,
        );

        const store = transaction.objectStore(Stores.SavedMessages);

        await store.add(messageData, messageData.messageId);

        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  }

  /**
   * @description Finds a single message from saved messages table.
   */
  async findSavedMessage(id: number): Promise<FullMessageData> {
    return this.idbService.dbPromise.then(async (db) => {
      const transaction = db.transaction(
        Stores.SavedMessages,
        Permissions.Read,
      );
      const store = transaction.objectStore(Stores.SavedMessages);
      const response: FullMessageData = await store.get(id);
      return response;
    });
  }

  /**
   * @description Gets all messages from saved messages table.
   */
  async getAllSavedMessages(): Promise<FullMessageData[]> {
    return this.idbService.dbPromise.then(async (db) => {
      const transaction = db.transaction(
        Stores.SavedMessages,
        Permissions.Read,
      );
      const store = transaction.objectStore(Stores.SavedMessages);
      const response: FullMessageData[] = await store.getAll();
      return response;
    });
  }

  /**
   * @description Gets all messages from messages table.
   */
  async getAllMessages(): Promise<FullMessageData[]> {
    return this.idbService.dbPromise.then(async (db) => {
      const transaction = db.transaction(Stores.Messages, Permissions.Read);
      const store = transaction.objectStore(Stores.Messages);
      const response: FullMessageData[] = await store.getAll();
      return response;
    });
  }

  /**
   * @description Delete a specific message from saved messages table.
   */
  async deleteSavedMessage(id: number): Promise<void> {
    return this.idbService.dbPromise.then(async (db) => {
      const transaction = db.transaction(
        Stores.SavedMessages,
        Permissions.Write,
      );
      const store = transaction.objectStore(Stores.SavedMessages);
      await store.delete(id);
    });
  }

  /**
   * @description Delete all messages from messages and saved messages tables.
   */
  async deleteMessages(): Promise<void> {
    const deleteMessagesPromise = this.idbService.dbPromise.then(async (db) => {
      const transaction = db.transaction(Stores.Messages, Permissions.Write);
      const store = transaction.objectStore(Stores.Messages);
      try {
        await store.clear();
      } catch (error) {
        console.error('Could not delete stored messages.');
        console.error(error);
      }
    });

    return deleteMessagesPromise;
  }

  /**
   * @description Delete all messages from messages and saved messages tables.
   */
  async deleteSavedMessages(): Promise<void> {
    const deleteSavedMessagesPromise = this.idbService.dbPromise.then(
      async (db) => {
        const transaction = db.transaction(
          Stores.SavedMessages,
          Permissions.Write,
        );
        const store = transaction.objectStore(Stores.SavedMessages);
        try {
          await store.clear();
        } catch (error) {
          console.error('Could not delete saved messages.');
          console.error(error);
        }
      },
    );

    return deleteSavedMessagesPromise;
  }

  /**
   * @description Delete all messages from messages and saved messages tables.
   */
  async deleteAllMessages(): Promise<void> {
    await this.deleteMessages();
    await this.deleteSavedMessages();
  }

  /**
   * @description Mark a message as read in IndexedDB and API.
   */
  markAsRead(
    message: FullMessageData,
    bypassLoader = false,
  ): Observable<ManageEmployeeMessageResponse> {
    const payload: ManageEmployeeMessageRequest = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      businessUnit:
        this.userService.emulatedOrDefaultBusinessUnit() ?? BusinessUnit.AAPI,
      empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      appSessionId: this.userService.appSession(),
      siteMinderEmpId: this.userService.employeeNumber(),
      actionType: 'READ',
      employeeMsgList: [
        {
          msgId: message.messageId,
          priorityNumber: message.priorityNumber,
        },
      ],
      deviceToken: '',
      uniqueValue: '',
    };

    // Exit early if the user is an admin.
    // In this case admin would be someone requesting this for another employee id other than their own.
    // However we still want the message to display so we're setting it here directly.
    if (payload.empIdLogin !== payload.siteMinderEmpId) {
      this.messageService.selectedMessage.set(message);
      return EMPTY;
    }

    return this.messageApiService
      .manageEmployeeMessage(payload, bypassLoader)
      .pipe(
        tap((x) => {
          if (x.success) {
            message.messageRead = 1;

            this.idbService.dbPromise.then(async (db) => {
              const transaction = db.transaction(
                Stores.Messages,
                Permissions.Write,
              );
              const store = transaction.objectStore(Stores.Messages);

              try {
                await store.put(message, message.messageId);
                await this.refreshMessages();
              } catch (error) {
                console.error('Could not mark message as read.');
                console.error(error);
              }
            });
          }
        }),
      );
  }

  /**
   * @description Mark a message as acknowledged in IndexedDB and API.
   */
  acknowledgeMessage(
    message: FullMessageData,
    bypassLoader = false,
  ): Observable<ManageEmployeeMessageResponse> {
    if (!message) return of();

    const payload: ManageEmployeeMessageRequest = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      businessUnit:
        this.userService.emulatedOrDefaultBusinessUnit() ?? BusinessUnit.AAPI,
      empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      appSessionId: this.userService.appSession(),
      siteMinderEmpId: this.userService.employeeNumber(),
      actionType: 'ACKNOWLEDGED',
      deviceToken: '',
      uniqueValue: '',
      employeeMsgList: [
        {
          msgId: message.messageId,
          priorityNumber: message.priorityNumber,
        },
      ],
    };

    return this.messageApiService
      .manageEmployeeMessage(payload, bypassLoader)
      .pipe(
        tap(() => {
          this.messageService.selectedMessage.set(undefined);
          this.messageService.selectedMenu.set(undefined);
          this.idbService.dbPromise.then(async (db) => {
            const transaction = db.transaction(
              Stores.Messages,
              Permissions.Write,
            );
            const store = transaction.objectStore(Stores.Messages);
            try {
              await store.delete(message.messageId);
              await this.refreshMessages();
            } catch (error) {
              console.error('Could not delete message.');
              console.error(error);
            }
          });
        }),
      );
  }

  /**
   * @description Restored a deleted message in IndexedDB and API.
   */
  restoreDeletedSelectedMessage(
    bypassLoader = false,
  ): Observable<ManageEmployeeMessageResponse> {
    const selected = this.messageService.selectedMessage();

    if (!selected) return of();

    const payload: ManageEmployeeMessageRequest = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      businessUnit:
        this.userService.emulatedOrDefaultBusinessUnit() ?? BusinessUnit.AAPI,
      empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      appSessionId: this.userService.appSession(),
      siteMinderEmpId: this.userService.employeeNumber(),
      actionType: 'RESTORE',
      deviceToken: '',
      uniqueValue: '',
      employeeMsgList: [
        {
          msgId: selected.messageId,
          priorityNumber: selected.priorityNumber,
        },
      ],
    };

    return this.messageApiService
      .manageEmployeeMessage(payload, bypassLoader)
      .pipe(
        tap(() => {
          this.messageService.selectedMessage.set(undefined);
          this.messageService.selectedMenu.set(undefined);
          this.idbService.dbPromise.then(async (db) => {
            const transaction = db.transaction(
              Stores.Messages,
              Permissions.Write,
            );
            const store = transaction.objectStore(Stores.Messages);
            try {
              await store.delete(selected.messageId); // will be in appropriate store on refresh
              await this.refreshMessages();
            } catch (error) {
              console.error('Could not restore message.');
              console.error(error);
            }
          });
        }),
      );
  }

  /**
   * @description Mark a message as deleted in IndexedDB and API.
   */
  deleteSelectedMessage(
    bypassLoader = false,
  ): Observable<ManageEmployeeMessageResponse> {
    const selected = this.messageService.selectedMessage();

    if (!selected) return of();

    const payload: ManageEmployeeMessageRequest = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      businessUnit:
        this.userService.emulatedOrDefaultBusinessUnit() ?? BusinessUnit.AAPI,
      empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      appSessionId: this.userService.appSession(),
      siteMinderEmpId: this.userService.employeeNumber(),
      actionType: 'DELETE',
      deviceToken: '',
      uniqueValue: '',
      employeeMsgList: [
        {
          msgId: selected.messageId,
          priorityNumber: selected.priorityNumber,
        },
      ],
    };

    return this.messageApiService
      .manageEmployeeMessage(payload, bypassLoader)
      .pipe(
        tap(() => {
          this.messageService.selectedMessage.set(undefined);
          this.idbService.dbPromise
            .then(async (db) => {
              const transaction = db.transaction(
                Stores.Messages,
                Permissions.Write,
              );
              const store = transaction.objectStore(Stores.Messages);
              try {
                await store.delete(selected.messageId);
                await this.refreshMessages();
              } catch (error) {
                console.error('Could not delete selected message.');
                console.error(error);
              }
            })
            .catch((error) => {
              console.error('Could not open DB.');
              console.error(error);
            });
        }),
      );
  }

  /**
   * @description Load all messages and message from API into messages table.
   * Message table is cleared out and reloaded with new messages.
   * Saved messages are filtered from the list.
   */
  async refreshMessages(bypassLoader = false): Promise<void> {
    if (
      !this.userService.apiDetails() ||
      this.userService.emulatedOrDefaultBusinessUnit() === undefined
    ) {
      await this.userService.setApiDetails();
    }

    this.messageService.fetchHi6Count();

    let apiMessagesWithoutBody: GetEmployeeMessagesResponse | undefined =
      undefined;
    if (this.userService.apiDetails()) {
      apiMessagesWithoutBody = await this.callGetEmployeeMessages(
        apiMessagesWithoutBody,
        bypassLoader,
      );
    }

    let savedMessages: FullMessageData[] = [];

    try {
      savedMessages = await this.getAllSavedMessages();
    } catch (error) {
      console.error('Could not get all messages.');
      console.error(error);
    }

    const newMessageHeaders =
      apiMessagesWithoutBody?.empMessages.messageDataArray.filter(
        (x) => !savedMessages?.some((y) => x.messageId === y.messageId),
      );

    const newMessages = newMessageHeaders?.map((m) => {
      return { ...m, body: null } as FullMessageData;
    });

    try {
      await this.deleteMessages();
    } catch (error) {
      console.error('Could not delete messages.');
      console.error(error);
    }

    this.idbService.dbPromise
      .then(async (db) => {
        const transaction = db.transaction(Stores.Messages, Permissions.Write);
        const store = transaction.objectStore(Stores.Messages);

        if (!newMessages) return;
        for (const m of newMessages) {
          try {
            await store.add(m, m.messageId);
          } catch (error) {
            this.insights.trackTrace({
              message: 'Could not add message to store',
              properties: {
                message: m,
                error,
                user: this.userService.employeeNumber(),
              },
            });
          }
        }
      })
      .catch((error) => {
        console.error('Could not open DB.', error);
      });

    const allMessages = await this.getAllMessages();
    this.messageService.allMessages.set(allMessages);
    this.messageService.savedMessages.set(
      sortArrayBy(savedMessages, 'effectiveDate', 'desc'),
    );

    this.messageService.lastRefreshTime.set(new Date());
  }

  /**
   * @description Returns messages with bodies for any message array passed into it.
   */
  populateMessageBody(message: FullMessageData, bypassLoader = false): void {
    const payload: GetCCIMsgDetailByMsgIdRequest = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      appSessionId: this.userService.appSession(),
      businessUnit:
        this.userService.apiDetails()?.businessUnit ?? BusinessUnit.AAPI,
      empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      msgId: message.messageId,
      siteMinderEmpId: this.userService.employeeNumber(),
    };

    this.messageApiService
      .getCciMsgDetailByMsgId(payload, bypassLoader)
      .subscribe((x) => {
        message.body = x.msgBody;

        this.idbService.dbPromise
          .then(async (db) => {
            const transaction = db.transaction(
              Stores.Messages,
              Permissions.Write,
            );
            const store = transaction.objectStore(Stores.Messages);
            await store.put(message, message.messageId);
            this.messageService.setSelectedMessage(message);
          })
          .catch((error) => {
            console.error('Could not open DB.', error);
          });
      });
  }

  private async getEmployeeMessagesPayload(): Promise<GetEmployeeMessagesRequest> {
    const employeeNumber = this.userService.emulatedOrDefaultEmployeeNumber();
    const airlineCode = this.userService.emulatedOrDefaultAirlineCode();

    const businessUnit = this.userService.emulatedOrDefaultBusinessUnit()!;
    await this.userService.setFourPartBitStatus(
      employeeNumber,
      airlineCode,
      businessUnit,
    );

    const payload: GetEmployeeMessagesRequest = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
      empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      appSessionId: this.userService.appSession(),
      siteMinderEmpId: this.userService.employeeNumber(),
      employeeMessageRequest: {
        airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
        businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
        empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
        appSessionId: this.userService.appSession(),
        siteMinderEmpId: this.userService.employeeNumber(),
        admin: this.userService.apiDetails()?.isAdmin ?? false,
        mailBox: this.userService.apiDetails()?.mailBox ?? '',
        contractMonth: this.userService.bidStatus()?.contractMonth ?? '',
        crewBase: this.userService.bidStatus()?.crewBase ?? '',
        crewDivision: this.userService.bidStatus()?.crewDivision ?? '',
        crewEquipment: this.userService.bidStatus()?.crewEquipment ?? '',
        crewSeat: this.userService.bidStatus()?.crewSeat ?? '',
        checkAirman: this.userService.bidStatus()?.checkAirman ?? false,
        fsmSupervisorNumber:
          this.userService.bidStatus()?.fsmSupervisorNumber ?? '',
        checkAirmanType: this.userService.bidStatus()?.checkAirmanType ?? '',
        departureStations:
          this.userService.bidStatus()?.departureStations ?? [],
      },
    };

    return payload;
  }

  private async callGetEmployeeMessages(
    apiMessagesWithoutBody: GetEmployeeMessagesResponse | undefined,
    bypassLoader = false,
  ): Promise<GetEmployeeMessagesResponse | undefined> {
    try {
      const payload = await this.getEmployeeMessagesPayload();
      apiMessagesWithoutBody = await lastValueFrom(
        this.messageApiService.getEmployeeMessages(payload, bypassLoader),
      );
    } catch (error) {
      this.insights.trackException({
        exception: new Error(
          `callGetEmployeeMessages(): Could not getEmployeeMessages. ${JSON.stringify(
            error,
          )}`,
        ),
      });
      console.error('Could not getEmployeeMessages.');
    }
    return apiMessagesWithoutBody;
  }
}
